<template>
	<span id="werbifi-widget">
		<transition name="fade">
			<!-- Widget Initial Size -->
			<div v-if="!isOpened" class="widget-wrapper" @click="isOpened = true">
				<div class="widget-body">
					<div class="logo-wrapper">
						<img src="https://cdn.werbifi.com/werbifi/google-logo.png" class="google-review-logo">
					</div>
					<div class="widget-body-wrapper">
						<span class="widget-body-wrapper-p">{{lang.google_rating}}</span>
						<br>
						<div class="grid-div widget-wrapper-rating">
							<div>
								<span v-show="rating" class="rating-text">{{rating.toFixed(1)}}</span>
							</div>
							<Stars :rating="rating" />
						</div>
						<br>
						<span class="reviews-number">
							<span> {{lang.total}} </span>
							<span v-show="totalGoogleReviews" class="mobile-only">{{totalGoogleReviews.toFixed(0)}}
								<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="17" height="17" viewBox="0 0 1792 1792">
									<path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#e7711b"></path>
								</svg>
							</span>
							<span> {{lang.reviews}} </span>
						</span>
					</div>
				</div>
				<br>
				<div v-if="poweredByWerbifi" class="widget-footer">
					<span class="footer-text widget-footer-text">{{lang.powered_by}} </span>
					<a href="https://werbifi.com" target="_blank">
						<img src="https://cdn.werbifi.com/werbifi/logo-app-icon.png" class="footer-mobile-logo">
						<img src="https://cdn.werbifi.com/werbifi/logo-light.png" class="footer-normal-logo">
					</a>
				</div>
			</div>
		</transition>


		<!-- Reviews List -->
		<transition name="fade">
			<div v-if="isOpened">
				<div id="widget-expand" style="display: block;">
					<span class="expand-close" @click="isOpened = false">✖</span>
					<div id="widget-expand-header" class="main-shadow">
						<img class="company-logo" style="width: 70px; height: 70px; float: left; margin-top: 7px; margin-left: -5px; margin-right: 10px; border-radius: 50px;" :src="company.logo"/>
						<br>
						<span class="company-name" style="margin-top: 50px;">{{company.name}}</span>
						<br>
						<div class="grid-div">
							<span class="rating-text" style="padding-left: 0!important;">{{rating}}</span>
							<Stars :rating="rating" style="padding-top: 4px;"/>
						</div>
					</div>
					<div class="reviews-list" >
						<div v-for="review in reviews" :key="review.id" class="reviews-list-wrapper">
							<img :src="review.payload.profile_photo_url" referrerpolicy="no-referrer" class="review-author-photo">
							<div>
								<span class="review-author-name">{{review.payload.author_name}}</span>
								<br>
								<span class="review-time">{{review.payload.relative_time_description}}</span>
								<br>
								<div class="grid-div">
									<div></div>
									<Stars :rating="review.payload.rating" />
									<br>
									<span class="text-review">{{review.payload.text}}</span>
								</div>
							</div>
						</div>

						<!-- Action Buttons -->
						<div class="bottom-action">
							<a :href="allReviewsGoogleLink" target="_blank" id="see-all-reviews">{{lang.show_all_reviews}}</a>
						</div>
					</div>
					<div class="reviews-footer main-shadow" :style="'height: ' + footerHeight + 'px;'">
						<div class="reviews-footer-wrapper">
							<span style="width: 90%; margin: 0 auto; padding: 10px 0;">
								<!-- Write Review Button -->
								<button v-if="!writeReview" @click="writeReview = true" class="write-review">
									{{lang.write_review}}
								</button>

								<!-- Write Review Wrapper -->
								<transition name="fade">
									<div v-if="writeReview" class="write-review-wrapper">
										<!-- Default Write Review - Stars -->
										<transition name="fade">
											<span v-if="!thanks">
												<div v-if="!newReview" class="write-review-text" style="margin-left: -15px;">
													{{lang.opinion}}
												</div>
					                            <star-rating v-if="!reviewWindowInterval && !newReview" v-model="newReview" :star-size="35"
					                                         active-color="#e3680e" :read-only="false"/>
												<div v-else-if="newReview > 3" class="loader"> </div>
											</span>
										</transition>

										<!-- Bad Review -> Ask for the feedback -->
										<transition name="fade">
											<span v-if="showFeedbackForm">
												<div class="write-review-text">
													{{lang.improve}}
												</div>
												<form @submit.prevent="submitReview">
													<textarea v-model="reviewComment" class="write-review-comment" rows="8" :placeholder="lang.your_feedback" />
													<br>
													<button class="write-review" style="line-height: 42px;" type="submit"> {{lang.submit}} </button>
												</form>
											</span>
										</transition>

										<!-- Thanks for the review message -->
										<transition name="fade">
											<div v-if="newReview <= 3 && thanks" class="write-review-text" style="padding: 18px 0;">
												{{lang.thanks_feedback}}
											</div>
											<div v-if="newReview > 3 && thanks" class="write-review-text" style="padding: 18px 0;">
												{{lang.thanks_review}}
											</div>
										</transition>
									</div>
								</transition>
							</span>
							<span v-if="poweredByWerbifi && !showFeedbackForm" style="width: 100%;">
								<span class="reviews-footer-text">{{lang.powered_by}} </span>
								<a href="https://werbifi.com" target="_blank">
									<img src="https://cdn.werbifi.com/werbifi/logo-dark.png" style="display: inline;" class="footer-logo">
								</a>
							</span>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</span>
</template>

<script>
import Stars from "./Stars";
import Reviews from "../services/Reviews";
import to from 'await-to-js';
import StarRating from 'vue-star-rating';
import langLibrary from '../lang';

export default {
  name: 'Widget',
  components: {Stars, StarRating},
  data (){
  	return {
	  widgetLang: 'de',
	  langLibrary,
	  poweredByWerbifi: true,
	  writeReview: false,
	  thanks: false,
	  badReview: false,
	  newReview: 0,
	  reviewComment: '',
	  reviewWindow: null,
	  reviewWindowInterval: null,

	  isOpened: false,
	  isMobile: false,
	  rating: 0,
	  totalGoogleReviews: 0,
	  company: {},
	  reviews: []
    }
  },
  computed: {
  	// Return current lang tree
  	lang() {
	  return this.langLibrary[this.widgetLang];
    },
    // Review List footer height in pixels
  	footerHeight() {
	  let heightInit = 130, heightExpand = 280;
	  if (!this.poweredByWerbifi){
	    heightInit = 85;
	    heightExpand = 235;
	  }
	  return this.showFeedbackForm ? heightExpand : heightInit;
    },
	  /**
	   * Return true if feedback form shold be opened, false otherwise
	   * @return {boolean|boolean}
	   */
  	showFeedbackForm() {
	  return this.newReview <= 3 && this.badReview && !this.thanks
    },
    // Redirect Link to all review on google
  	allReviewsGoogleLink() {
	  return 'https://search.google.com/local/reviews?placeid=' + this.company.place_id
    }
  },
  async mounted() {
  	// Fetch review data from API
    const params = new URLSearchParams(window.location.search)
    let [err, response] = await to(Reviews.getData(params.get('key') || location.hostname));
  	if (err) return console.error('Unable to fetch widget data. Please call at support: info@werbifi.com', err)

    // Get browser language
    const windowLang = window.navigator.language.slice(0, 2);
  	// Set language to provided lang parameter if provided
    // If not, set the language by browser language, and default to german
    this.widgetLang = params.get('lang') && Object.keys(params.get('lang')).includes(windowLang) ? params.get('lang') : Object.keys(this.langLibrary).includes(windowLang) ? windowLang : 'de';

    // Store relevant review data
  	this.rating = response.data.current_google_rating;
  	this.totalGoogleReviews = response.data.current_google_reviews;
    this.reviews = response.data.reviews.filter(review => review.vote >= 3);
    this.company = response.data.company;
  },
  methods: {
    reset () {
      this.newReview = 0;
      this.reviewComment = '';
      this.thanks = false;
      this.isOpened = false;
      this.writeReview = false;
      this.reviewWindow = null;
      this.reviewWindowInterval = null;
      this.badReview = false;
      this.$forceUpdate();
    },

    // Submit review, both bad and good and reset the widget after 3 seconds
    async submitReview () {
      if (this.newReview !== 0) {
        const [err] = await to(Reviews.writeReview(this.company.id, this.newReview, this.reviewComment, 'widget'))
        if (err) return console.error('Unable to write a new review. Please call at support: info@werbifi.com')
        if (this.newReview <= 3) {
          this.thanks = true;
          setTimeout(() => {
            this.reset();
          }, 3000);
        }
      }
    }
  },
  watch: {

  	// Detect star press and react accordingly
    // Good review (4 and 5) -> Open Google review popup, start the interval for loading
    // Bad review (1, 2 and 3) -> Open feedback form
    newReview(nv) {
      if (nv !== 0) {
        if (nv === 4 || nv === 5) {
          this.submitReview();
          let size = 630, percent = 40;
          if (window.screen) {
            const newSize = window.screen.availWidth * percent / 100;
            size = newSize < size ? size : newSize;
          }
          this.reviewWindow = window.open('https://search.google.com/local/writereview?placeid=' + this.company.place_id, '', `width=${size},height=${size}`);
          this.reviewWindowInterval = setInterval(() => {
            if(this.reviewWindow.closed) {
              clearInterval(this.reviewWindowInterval);
              this.reviewWindow = null;
              setTimeout(() => {
                this.reset();
              }, 3000);
              this.thanks = true;
            }
          }, 1000);
        } else {
          this.badReview = true
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	#werbifi-widget {
		letter-spacing: 0px;

		#werbifi-widget {
			all: initial;
			* {
				all: unset;
			}
			:not(svg|*) {
				all: unset;
			}
		}

		.widget-wrapper {
			top: initial;
			position: fixed;
			z-index: 77777;
			left: 30px;
			bottom: 30px;
			background-color: #fff;
			color: #35596f;
			cursor: pointer;
			box-shadow: 1px 2px 3px #404040;
			border-top: 3px solid #173a58;
			transition: 0.25s;
			font-size: 11px;
			text-align: initial;
			border-radius: 3px;
		}

		.widget-body {
			display: inline-grid;
			grid-template-columns: auto auto;
			justify-items: center;
			align-items: center;
			font-family: arial, sans-serif;
			line-height: 20px;
			margin: 5px 10px;
		}

		.widget-body-wrapper {
			padding: 5px 5px;
		}

		.widget-body-wrapper-p {
			font-size: 13px;
			font-weight: bold;
		}

		.grid-div {
			display: inline-grid;
			grid-template-columns: auto auto auto;
			color: #35596f;
		}

		.rating-text {
			padding-left: 0px!important;
		}

		.reviews-number {
			font-size: 11px;
		}

		img.footer-normal-logo {
			display: inline;
		}

		.footer-normal-logo {
			height: 13px;
			padding-left: 0px;
			max-width: 100px;
			margin: 0px;
		}

		.google-review-logo {
			padding: 0 5px;
			width: 100%;
			max-width: 45px;
		}

		.review-author-photo {
			padding: 0 5px;
			width: 100%;
			max-width: 50px;
		}

		img, picture {
			display: block;
			margin: 0 auto;
			max-width: 100%;
			height: auto;
		}

		img, legend {
			border: 0;
		}

		img {
			vertical-align: middle;
			border: 0;
		}

		.rating-text {
			font-size: 20px;
			padding: 5px 5px;
		}

		.footer-text {
			vertical-align: middle;
			display: inline;
			color: rgb(116, 116, 116);
			letter-spacing: 1px;
			font-size: 15px;
		}

		a {
			color: #274557;
		}

		.widget-footer {
			text-align: center;
			display: block;
			border-radius: 3px;
			padding: 4px 10px;
			background-image: linear-gradient(90deg, #0c1117 0%, #173a58 100%);
		}

		.widget-footer-text {
			font-size: 9px;
			color: #eeeeee;
			font-family: arial, sans-serif;
		}

		.widget-wrapper:hover {
			color: #888888;
			box-shadow: 1px 2px 5px #888888;
		}

		#widget-expand {
			transition-duration: 0.5s;
			position: fixed;
			display: none;
			z-index: 777777;
			background: #fff;
			left: 0px;
			top: 0px;
			bottom: 0px;
			width: 360px;
			border: solid 1px rgba(43, 72, 90, 0.63);
			border-top-right-radius: 10px;
			border-top-left-radius: 10px;
			text-align: initial;
		}

		.main-shadow {
			-webkit-box-shadow: 0 5px 3px -3px rgba(43, 72, 90, 0.63);
			-moz-box-shadow: 0 5px 3px -3px rgba(43, 72, 90, 0.63);
			box-shadow: 0 5px 3px -3px rgba(43, 72, 90, 0.63);
		}

		#widget-expand-header {
			padding: 10px 0px 10px 20px;
			font-family: arial, sans-serif;
			line-height: 20px;
		}

		.expand-close {
			float: right;
			cursor: pointer;
			font-family: sans-serif;
			font-weight: bolder;
			line-height: 20px;
			color: #f50420;
			padding: 5px 10px;
			transition: 0.25s;
		}

		.expand-close:hover {
			color: #5c5b5b;
		}

		.widget-body {
			display: inline-grid;
			grid-template-columns: auto auto;
			justify-items: center;
			align-items: center;
			font-family: arial, sans-serif;
			line-height: 20px;
			margin: 5px 10px
		}

		.reviews-list {
			overflow-y: scroll;
			overflow-x: hidden;
			padding-top: 15px;
			height: 100vh;
			justify-items: left;
			align-items: center;
			font-family: Arial, Helvetica, sans-serif;
			line-height: 22px;
			background-color: #f6f6f6;
			font-size: 13px;
		}

		.reviews-list-wrapper {
			display: inline-grid;
			grid-template-columns: 18% 73%;
			width: 100%;
			padding: 0px 0px 10px 10px;
		}

		.review-author-photo {
			width: 100%;
			max-width: 50px;
		}

		.rating-text {
			font-size: 20px;
			padding: 5px 5px;
		}

		.grid-div {
			display: inline-grid;
			grid-template-columns: auto auto;
			color: #35596f;
		}

		.company-name {
			font-weight: bold;
			font-size: 18px;
			color: #35596f;
		}

		.write-review-text {
			font-size: 16px;
			color: #305971;
			margin-bottom: 9px;
			font-weight: bold;
		}

		.review-author-name {
			font-size: 1.1em;
			font-weight: bold;
			color: #35596f;
		}

		.review-time {
			color: hsl(0, 0%, 61%);
		}

		.text-review {
			color: #222;
			line-height: 18px;
			font-size: 13px;
			padding-left: 5px;
			white-space: pre-wrap;
		}

		.bottom-action {
			font-size: 15px;
			text-align: center;
			line-height: 30px;
			padding-bottom: 400px;
			padding-top: 10px;
		}

		#see-all-reviews{
			border-bottom: 2px solid #35596f;
			padding: 3px 5px;
			transition: 0.25s;
			text-decoration: none;
			color: #35596f;
		}

		#see-all-reviews:hover{
			border-bottom: 2px solid #767676;
			cursor: pointer;
			color: #7d7c7c;
		}

		.write-review {
			margin: 5px auto;
			outline:none;
			border: 2px solid #35596f;
			border-radius: 15px;
			padding: 3px 10px;
			transition: 0.25s;
			text-decoration: none;
			text-transform: none;
			letter-spacing: 0px;
			background-color: #f6f6f6;
			display: block;
			font-family: arial, sans-serif;
			color: #35596f;
			width: 100%;
			font-size: 15px;
			font-weight: bold;
			line-height: 49px;
		}

		.write-review:hover {
			border: 2px solid #767676;
			cursor: pointer;
			color: #7d7c7c;
		}

		.write-review:focus {
			outline: none !important;
		}

		.write-review-comment {
			display: initial;
			margin: 0 auto;
			border: 2px solid #35596f;
			border-radius: 15px!important;
			padding: 10px 10px;
			transition: 0.25s;
			text-decoration: none;
			font-family: arial, sans-serif;
			text-transform: none;
			color: #35596f;
			width: 90%!important;
			font-size: 12px;
			font-weight: bold;
			line-height: 17px;
			box-sizing: initial;
			-webkit-box-sizing: initial;
		}

		.write-review-comment:focus {
			outline: none !important;
			border-radius: 15px!important;
		}

		.write-review-comment:hover {
			border: 2px solid #767676;
			cursor: pointer;
			color: #7d7c7c;
			border-radius: 15px!important;
		}

		.write-review-wrapper {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}

		.footer-logo {
			width: 100%;
			max-width: 100px;
			padding-left: 5px;
		}

		.reviews-footer {
			transition-duration: 1s;
			bottom: 0px;
			position: fixed;
			background-color: #ffffff;
			width: 360px;
			height: 130px;
			font-family: arial, sans-serif;
			line-height: 20px;
		}

		.reviews-footer-wrapper {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			text-align: center;
			float: left;
		}

		.reviews-footer-text {
			font-size: 14px;
			color: #404040;
		}

		.widget-wrapper.widget-body>div:first-child {
			padding-left: 5px;
		}


		/* Mobile Responsive */
		.footer-mobile-logo {
			display: none;
		}

		.reviews-number .mobile-only svg {
			display: none;
		}

		@media (max-width: 767px) {
			.footer-mobile-logo {
				display: block;
				width: 25px;
				margin: 0 auto;
			}
			.widget-body {
				grid-template-columns: none;
			}
			.widget-wrapper {
				box-sizing: initial;
				-webkit-box-sizing: initial;
				letter-spacing: 0px;
				border-top-width: 3px;
				bottom: auto;
				top: 85%;
				left: 0;
				transform: translateY(-40%);
				box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.11);
			}

			.footer-text,
			img.footer-normal-logo,
			.widget-body-wrapper-p,
			.widget-wrapper-rating .stars,
			.widget-body-wrapper br {
				display: none;
			}
			.reviews-number {
				font-size: 0;
				display: block;
				text-align: center;
				width: 100%;
				line-height: 11px;
				border-bottom: 1px solid #fdfdfd;
			}
			.widget-body-wrapper {
				padding-bottom: 0;
			}
			.reviews-number .mobile-only {
				font-size: 15px;
				color: #05304c;
				font-weight: 600;
				line-height: 18px;
			}
			.reviews-number .mobile-only svg {
				display: inline-block;
				vertical-align: baseline;
				width: 12px;
				height: 12px;
				margin-left: 1px;
				margin-bottom: -1px;
			}
			.logo-wrapper .google-review-logo {
				width: 20px;
			}
			.widget-body {
				margin: 6px 5px;
			}
			.logo-wrapper {
				width: 100%;
				text-align: center;
				padding-bottom: 10px;
				border-bottom: 1px solid #F2F4F6;
			}
			.widget-footer {
				background: #fff;
				padding: 0;
				margin: 0 5px 9px;
			}
			.widget-wrapper-rating .rating-text {
				display: block;
				padding: 0;
				width: 100%;
				text-align: center;
				font-weight: 700;
				color: #0A3957;
				margin-bottom: 4px;
				margin-left: 4px;
			}
		}

		/* Vue Transitions */
		.fade-enter-active, .fade-leave-active {
			transition: opacity .5s;
		}
		.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
			opacity: 0;
		}









		/* Spinner */
		.loader {
			font-size: 10px;
			margin: 14px auto;
			text-indent: -9999em;
			width: 4em;
			height: 4em;
			border-radius: 50%;
			background: #155293;
			background: -moz-linear-gradient(left, #155293 10%, rgba(21,82,147, 0) 42%);
			background: -webkit-linear-gradient(left, #155293 10%, rgba(21,82,147, 0) 42%);
			background: -o-linear-gradient(left, #155293 10%, rgba(21,82,147, 0) 42%);
			background: -ms-linear-gradient(left, #155293 10%, rgba(21,82,147, 0) 42%);
			background: linear-gradient(to right, #155293 10%, rgba(21,82,147, 0) 42%);
			position: relative;
			-webkit-animation: load3 1.4s infinite linear;
			animation: load3 1.4s infinite linear;
			-webkit-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
		}
		.loader:before {
			width: 50%;
			height: 50%;
			background: rgba(21, 82, 147, 0);
			border-radius: 100% 0 0 0;
			position: absolute;
			top: 0;
			left: 0;
			content: '';
		}
		.loader:after {
			background: #fff;
			width: 75%;
			height: 75%;
			border-radius: 50%;
			content: '';
			margin: auto;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
		@-webkit-keyframes load3 {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@keyframes load3 {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
	}
</style>
