import Api from '@/services/Api'

export default {
  getData (key) {
    return Api().get('/review/google/' + key)
  },
	writeReview (id, vote, text, source) {
		return Api().post('/company/' + id + '/review/write', { vote, text, source })
	}
}
