import Vue from 'vue/dist/vue.esm'
import Widget from './components/Widget'

// Create widget div and bind it to the dom when dom is ready
document.addEventListener('DOMContentLoaded', function(){
	let widgetDiv = window.document.createElement('div');
	widgetDiv.id = 'widgetDiv';
	let body = document.getElementsByTagName('body')[0];

	if (body) {
		body.appendChild(widgetDiv);
		body.onload = () => {
			new Vue({
				render: h => h(Widget),
			}).$mount('#widgetDiv');
		};
	}
}, false);
