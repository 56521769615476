export default {
	en: {
		google_rating: 'Google Rating',
		total: 'Based on',
		reviews: 'Reviews',
		powered_by: 'powered by',
		write_review: 'Write a review',
		show_all_reviews: 'Show all reviews',
		opinion: 'We appreciate your opinion',
		improve: 'What can we improve?',
		submit: 'Submit',
		your_feedback: 'Your Feedback',
		thanks_feedback: 'Thank you for your feedback!',
		thanks_review: 'Thanks for your review!',
	},
	de: {
		google_rating: 'Google Bewertung',
		total: 'Insgesamt',
		reviews: 'Rezensionen',
		powered_by: 'unterstützt von',
		write_review: 'Bewertung schreiben',
		show_all_reviews: 'Alle Rezensionen anzeigen',
		opinion: 'Wir schätzen Ihre Meinung',
		improve: 'Was können wir verbessern?',
		submit: 'Abschicken',
		your_feedback: 'Ihr Feedback',
		thanks_feedback: 'Vielen Dank für Ihr Feedback!',
		thanks_review: 'Vielen Dank für Ihre Bewertung!',
	}
}
