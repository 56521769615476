import axios from 'axios'

export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT + '/v1',
    headers: {
      Authorization: `Bearer ` + localStorage.getItem('token'),
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json'
    },
  })
}
